import { apiBaseUrl, prod } from "../config";
import { Service } from "../models/Service";
import User from "../models/User";
import testResponse from "./data/response";

const statusCodeUnauthorized = 401;

interface ApiResponse {
  user: User;
  services: Service[];
}

interface ServiceToRefresh {
  namespace: string;
  name: string;
  infinityVersion: string;
}

export class AuthError extends Error {}

export const getServices = async (): Promise<ApiResponse> => {
  if (prod) {
    const url = `${apiBaseUrl}/api/resources?all=true"`;
    const response = await fetch(url);
    const data = await response.json();
    if (response.status === statusCodeUnauthorized) {
      throw new AuthError();
    }
    data.services.sort((a: Service, b: Service) =>
      a.name.localeCompare(b.name)
    );
    return data;
  }

  testResponse.services.sort((a: Service, b: Service) =>
    a.name.localeCompare(b.name)
  );
  return testResponse;
};

// tslint:disable-next-line: max-classes-per-file
export default class Api {
  public static refreshServices(services: Service[]): void {
    const request = {
      services: new Array<ServiceToRefresh>()
    };

    services.forEach(service => {
      request.services.push({
        namespace: service.namespace,
        name: service.name,
        infinityVersion: service.infinity.version
      });
    });
    if (prod) {
      fetch(`${apiBaseUrl}/api/resources/refresh`, {
        method: "POST",
        body: JSON.stringify(request)
      })
        .then(response => {
          console.log(response);
        })
        .catch((e: Error) => {
          console.error(e);
        });
    }
  }
}
