import {
  createStyles,
  TableCell,
  Tooltip,
  withStyles,
  WithStyles
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import React from "react";

interface Props extends WithStyles<typeof styles> {
  title: string;
  helpText: string;
}

const styles = () =>
  createStyles({
    infoIcon: {
      fontSize: "16px",
      paddingLeft: "3px"
    },
    paddedTableHeader: {
      display: "flex",
      alignItems: "center"
    }
  });

const HeaderWithHelp = withStyles(styles)(
  ({ title, helpText, classes }: Props) => {
    if (helpText) {
      return (
        <TableCell>
          <div className={classes.paddedTableHeader}>
            {title}
            <Tooltip title={helpText}>
              <Icon fontSize="small" className={classes.infoIcon}>
                info_outline
              </Icon>
            </Tooltip>
          </div>
        </TableCell>
      );
    } else {
      return <TableCell>{title}</TableCell>;
    }
  }
);

export default HeaderWithHelp;
