import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import React from "react";
import { Service } from "../models/Service";

function ListItemLink(props: any) {
  return <ListItem button component="a" {...props} />;
}

interface Props {
  service: Service;
  onOpenServiceOwner(): void;
}
const numericRegex = /^[0-9]+$/;
const datadogDashboardBase = "https://app.datadoghq.eu";
const inifnityV2DatadogDashboardId = "9tm-fi2-utc";

const ServiceActions = ({ service, onOpenServiceOwner }: Props) => {
  const engine = "kubernetes";
  const datadogDashboardId =
    service.service.datadogDashboardId || inifnityV2DatadogDashboardId;
  const datadogDashboardPrefix = `${datadogDashboardBase}${
    numericRegex.test(datadogDashboardId) ? "/dash" : "/dashboard"
  }`;
  const metricsDashboardUrl = `${datadogDashboardPrefix}/${datadogDashboardId}?tpl_var_service-name=${
    service.name
  }${
    service.service.ecsServiceName
      ? `&tpl_var_ecs-service-name=${service.service.ecsServiceName.toLowerCase()}`
      : ""
  }`;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(
    undefined
  );
  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(undefined);
  }

  return (
    <div>
      <IconButton
        aria-owns={anchorEl ? "service-actions" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Icon>more_vert</Icon>
      </IconButton>
      <Menu
        id="service-actions"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ListItemLink
          onClick={handleClose}
          target="_blank"
          href={`https://app.datadoghq.eu/logs?query=service%3A${
            service.name
          }%20OR%20%28service%3Akubelet%20AND%20${service.name}%29`}
        >
          <ListItemIcon>
            <Icon>format_align_left_icon</Icon>
          </ListItemIcon>
          <ListItemText>Logs</ListItemText>
        </ListItemLink>
        <ListItemLink
          onClick={handleClose}
          target="_blank"
          href={metricsDashboardUrl}
        >
          <ListItemIcon>
            <Icon>insert_chart</Icon>
          </ListItemIcon>
          <ListItemText>Metrics</ListItemText>
        </ListItemLink>
        <ListItemLink
          onClick={handleClose}
          target="_blank"
          href={`https://app.datadoghq.eu/event/stream?query=source:${engine} ${
            service.name
          }`}
        >
          <ListItemIcon>
            <Icon>insert_chart</Icon>
          </ListItemIcon>
          <ListItemText>Lifecycle events</ListItemText>
        </ListItemLink>
        {service.service.status.dns !== undefined ? (
          <ListItemLink
            onClick={handleClose}
            target="_blank"
            href={"https://" + service.service.status.dns}
          >
            <ListItemIcon>
              <Icon>web</Icon>
            </ListItemIcon>
            <ListItemText>Service</ListItemText>
          </ListItemLink>
        ) : null}
        <ListItemLink onClick={onOpenServiceOwner}>
          <ListItemIcon>
            <Icon>info</Icon>
          </ListItemIcon>
          <ListItemText>Service Information</ListItemText>
        </ListItemLink>
      </Menu>
    </div>
  );
};

export default ServiceActions;
