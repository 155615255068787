import { connect } from "react-redux";
import Header from "../components/Header";
import User from "../models/User";

export interface ApplicationState {
  user: User;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    user: state.user
  };
};

const HeaderContainer = connect(mapStateToProps)(Header);

export default HeaderContainer;
