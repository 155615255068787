import { Action, Dispatch } from "redux";
import { apiBaseUrl } from "../config";
import { Service } from "../models/Service";
import User from "../models/User";
import { AuthError, getServices } from "../services/api";

export const ACTION_SERVICES_FETCH_SUCCESS = "SERVICES_FETCH_SUCCESS";
export const ACTION_SERVICES_FETCH_ERROR = "SERVICES_FETCH_ERROR";
export const ACTION_SERVICES_SET_FILTER = "SERVICES_SET_FILTER";
export const ACTION_SERVICES_SET_FILTER_NAMESPACES =
  "SERVICES_SET_FILTER_NAMESPACES";
export const ACTION_SERVICES_SET_PAGE = "SERVICES_SET_PAGE";
export const ACTION_SERVICES_SET_ANNOUNCEMENT_BANNER =
  "SET_ANNOUNCEMENT_BANNER";

const announcementBannerState = "infinity-announcement-banner-state";

export interface ActionServicesFetchSuccess extends Action {
  type: "SERVICES_FETCH_SUCCESS";
  services: Service[];
  user: User;
}

export interface ActionServicesFetchError extends Action {
  type: "SERVICES_FETCH_ERROR";
  errorMessage: string;
}

export interface ActionServicesSetFilter extends Action {
  type: "SERVICES_SET_FILTER";
  filter: string;
}

export interface ActionServicesSetFilterNamespaces extends Action {
  type: "SERVICES_SET_FILTER_NAMESPACES";
  filteredNamespaces: string[];
}

export interface ActionServicesSetPage extends Action {
  type: "SERVICES_SET_PAGE";
  page: number;
}

export interface SetAnnouncementBannerStateAction extends Action {
  type: "SET_ANNOUNCEMENT_BANNER";
  state: boolean;
}

export type AppActions =
  | ActionServicesFetchSuccess
  | ActionServicesFetchError
  | ActionServicesSetFilter
  | ActionServicesSetFilterNamespaces
  | SetAnnouncementBannerStateAction
  | ActionServicesSetPage;

export function dispatchFetchServicesSuccess(
  services: Service[],
  user: User
): ActionServicesFetchSuccess {
  return {
    type: ACTION_SERVICES_FETCH_SUCCESS,
    services,
    user
  };
}

export function dispatchFetchServicesError(e: Error): ActionServicesFetchError {
  return {
    type: ACTION_SERVICES_FETCH_ERROR,
    errorMessage: e.message
  };
}

export function dispatchSetFilter(filter: string): ActionServicesSetFilter {
  return {
    type: ACTION_SERVICES_SET_FILTER,
    filter
  };
}

export function dispatchSetFilterNamespaces(
  namespaces: string[]
): ActionServicesSetFilterNamespaces {
  return {
    type: ACTION_SERVICES_SET_FILTER_NAMESPACES,
    filteredNamespaces: namespaces
  };
}

export function dispatchSetPage(page: number): ActionServicesSetPage {
  return {
    type: ACTION_SERVICES_SET_PAGE,
    page
  };
}

export function actionFetchServices() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await getServices();
      return dispatch(
        dispatchFetchServicesSuccess(response.services, response.user)
      );
    } catch (e) {
      const error = e as Error;
      if (error instanceof AuthError) {
        window.location.href = `${apiBaseUrl}/auth?redirect_url=${
          window.location.pathname
        }`;
      }
      return dispatch(dispatchFetchServicesError(error));
    }
  };
}

export const persistAnnouncementBannerState = (state: boolean) => {
  return async (dispatch: Dispatch) => {
    localStorage.setItem(announcementBannerState, state.toString());
    return dispatch(setAnnouncementBannerState(state));
  };
};

export const setAnnouncementBannerState = (
  state: boolean
): SetAnnouncementBannerStateAction => {
  return {
    type: ACTION_SERVICES_SET_ANNOUNCEMENT_BANNER,
    state
  };
};

export const fetchAnnouncementBannerState = () => {
  return async (dispatch: Dispatch) => {
    const state = localStorage.getItem(announcementBannerState);
    return dispatch(
      setAnnouncementBannerState(state === "true" || state === null)
    );
  };
};
