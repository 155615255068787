import { Tooltip, Typography } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import Warning from "@material-ui/icons/Warning";
import React from "react";
import { Service } from "../models/Service";

interface Props extends WithStyles<typeof styles> {
  service: Service;
  needsInformation: boolean;
  onClick(): void;
}

const styles = () =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center"
    },
    warnIcon: {
      color: "#F7C81E"
    },
    versionDocLink: {
      textDecoration: "none"
    },
    tooltipOpenInformation: {
      cursor: "pointer"
    }
  });

const ServiceName = withStyles(styles)(
  ({ needsInformation, service, classes, onClick }: Props) => {
    return (
      <div className={classes.root}>
        <Typography className={classes.root}>
          {service.name}
          {needsInformation && (
            <Tooltip
              title="Missing team or owner information"
              className={classes.tooltipOpenInformation}
              onClick={onClick}
            >
              <Warning className={classes.warnIcon} />
            </Tooltip>
          )}
        </Typography>
      </div>
    );
  }
);

export default ServiceName;
