import { withStyles, WithStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import TextField from "@material-ui/core/TextField";
import React from "react";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudDownload";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "flex-end",
      flexWrap: "wrap"
    },
    input: {
      margin: theme.spacing.unit * 2,
      width: theme.spacing.unit * 50
    },
    select: {
      margin: theme.spacing.unit * 2,
      width: theme.spacing.unit * 25
    },
    flexbox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
      color: "#2a7cca"
    }
  });

interface Props extends WithStyles<typeof styles> {
  onFilterChange: any;
  onFilterNamespaceChange: any;
  namespaces: string[];
  filteredNamespaces: string[];
}

// eslint-disable-next-line jsx-a11y/anchor-has-content
const Link = (props: any) => <a {...props} />;

const FilterBar = withStyles(styles)(
  ({
    onFilterChange,
    onFilterNamespaceChange,
    namespaces,
    filteredNamespaces,
    classes
  }: Props) => (
    <div className={classes.flexbox}>
      <form className={classes.container} noValidate autoComplete="off">
        <FormControl className={classes.select}>
          <InputLabel>Namespaces</InputLabel>
          <Select
            multiple
            onChange={event => onFilterNamespaceChange(event.target.value)}
            value={filteredNamespaces}
          >
            <MenuItem disabled value="">
              <em>Namespaces</em>
            </MenuItem>
            {namespaces.map(name => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          placeholder="Filter service names..."
          label="Filter"
          autoFocus={true}
          className={classes.input}
          onChange={event => onFilterChange(event.target.value.trim())}
        />
      </form>
      <Button
        variant="outlined"
        size="medium"
        color="primary"
        component={Link}
        href={"/api/resources/export?all=true"}
      >
        Export
        <CloudUploadIcon className={classes.rightIcon} />
      </Button>
    </div>
  )
);

export default withStyles(styles)(FilterBar);
