import { Chip, Tooltip } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import React from "react";
import { ServiceStatus } from "../models/Service";

interface Props extends WithStyles<typeof styles> {
  status: ServiceStatus;
}

const styles = (_: Theme) =>
  createStyles({
    avatarGreen: {
      borderColor: "rgb(79, 139, 55)"
    },
    avatarYellow: {
      borderColor: "rgb(246, 201, 33)"
    },
    avatarRed: {
      borderColor: "rgb(196, 37, 37)"
    },
    avatar: {
      width: 60,
      borderWidth: 2
    }
  });

const ServiceState = withStyles(styles)(({ status, classes }: Props) => {
  const stateMapping: { [id: string]: { [id: string]: string } } = {
    READY: {
      class: classes.avatarGreen,
      message: "Ready"
    },
    NOT_READY: {
      class: classes.avatarRed,
      message: "Not Ready"
    },
    UNKNOWN: {
      class: classes.avatarYellow,
      message: "Unknown (Service might be scaling out)"
    },
    UPDATE_FAILED: {
      class: classes.avatarRed,
      message: "Update Failed (It will automatically roll back)"
    },
    ROLLBACK_FAILED: {
      class: classes.avatarRed,
      message:
        "Rollback Failed (Check the Infinity FAQ and contact us in #application-platform)"
    },
    ROLLBACK_COMPLETE: {
      class: classes.avatarYellow,
      message:
        "Rollback Complete (Check your Cloudformation stack to see why it rolled back)"
    },
    DELETING: {
      class: classes.avatarYellow,
      message: "Deleting"
    },
    UPDATING: {
      class: classes.avatarYellow,
      message: "Updating"
    }
  };

  // workaround: this should be fixed in the API
  const availableReplicas = status.availableReplicas
    ? status.availableReplicas
    : 0;
  const replicas = status.replicas ? status.replicas : 0;

  return (
    <Tooltip title={stateMapping[status.state].message} placement="right">
      <Chip
        className={`${classes.avatar} ${stateMapping[status.state].class}`}
        label={availableReplicas + "/" + replicas}
        variant="outlined"
      />
    </Tooltip>
  );
});

export default ServiceState;
