import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import React from "react";
import { ServiceOwner as ServiceOwnerType } from "../models/Service";

interface ServiceTableProps extends WithStyles<typeof styles> {
  title: string;
  value?: string;
}

interface OwnerOverlayProps {
  open: boolean;
  owner: ServiceOwnerType;
  onRequestClose(): void;
}

interface ValueProps {
  children?: React.ReactNode;
}

const empty = (value?: string) => !(value && value !== "");

const styles = (theme: Theme) =>
  createStyles({
    badgePadding: {
      paddingRight: theme.spacing.unit * 3
    },
    wrapText: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    },
    slPad: {
      paddingLeft: theme.spacing.unit * 3
    }
  });

const Value = ({ children }: ValueProps) =>
  children && children !== "" ? <span>{children}</span> : <span>-</span>;

const ServiceTableRow = withStyles(styles)(
  ({ classes, title, value }: ServiceTableProps) => (
    <tr>
      <td>
        <DialogContentText>{title} </DialogContentText>
      </td>
      <td className={classes.slPad}>
        <DialogContentText>
          <Value>{value}</Value>
        </DialogContentText>
      </td>
    </tr>
  )
);

const OwnerOverlay = ({ open, onRequestClose, owner }: OwnerOverlayProps) => {
  return (
    <Dialog onClose={onRequestClose} aria-labelledby="dialog-title" open={open}>
      <DialogTitle id="dialog-title">Service information</DialogTitle>
      <DialogContent>
        <table>
          <tbody>
            <ServiceTableRow title="Owner" value={owner.owner} />
            <ServiceTableRow title="Team" value={owner.team} />
            <ServiceTableRow title="Segment" value={owner.segment} />
            <ServiceTableRow title="Vertical" value={owner.vertical} />
            <ServiceTableRow
              title="AWS Account Email"
              value={owner.awsAccountEmail}
            />
          </tbody>
        </table>
        {empty(owner.owner) ||
        empty(owner.team) ||
        empty(owner.segment) ||
        empty(owner.vertical) ? (
          <DialogContentText>
            <br />
            <br />
            Please refer to the tagging guidelines{" "}
            <a
              href="https://docs.services.as24.tech/best-practices/tagging/#recommended-tags"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </DialogContentText>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OwnerOverlay;
