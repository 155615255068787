import { connect } from "react-redux";
// @ts-ignore
import { withSizes } from "react-sizes";
import { Dispatch } from "redux";
import * as Actions from "../actions";
import ServiceList from "../components/ServiceList";
import { AppState } from "../reducers";

const mapStateToProps = (state: AppState) => {
  let filteredServices = state.services;
  if (state.filteredNamespaces.length !== 0) {
    filteredServices = filteredServices.filter(service =>
      state.filteredNamespaces.includes(service.namespace.toLocaleLowerCase())
    );
  }
  if (state.filter != null) {
    filteredServices = filteredServices.filter(service =>
      service.name
        .toLocaleLowerCase()
        .includes(state.filter.toLocaleLowerCase())
    );
  }

  return {
    services: filteredServices,
    fetchState: state.fetchState,
    errorMessage: state.errorMessage
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setPage: (page: number) => dispatch(Actions.dispatchSetPage(page)),
  loadData: () => {
    // @ts-ignore
    setInterval(() => dispatch(Actions.actionFetchServices()), 5000);
    // @ts-ignore
    dispatch(Actions.actionFetchServices());
  }
});

const ServiceListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(ServiceList);

// @ts-ignore
const mapSizesToProps = ({ width }) => ({
  width
});

export default withSizes(mapSizesToProps)(ServiceListContainer);
