import * as Actions from "../actions";
import { Service } from "../models/Service";
import User from "../models/User";

export interface AppState {
  fetchState: string; // 'INIT', 'LOADING' | 'LOADED' | 'ERROR',
  services: Service[];
  filter: string;
  filteredNamespaces: string[];
  user: User | null;
  currentPage: number;
  errorMessage?: string;
  announcementBannerShown: boolean;
}

export function defaultAppState(): AppState {
  return {
    fetchState: "INIT",
    services: [],
    filter: "",
    filteredNamespaces: [],
    user: null,
    currentPage: 1,
    announcementBannerShown: false
  };
}

export function mainReducer(
  state: AppState = defaultAppState(),
  action: Actions.AppActions
): AppState {
  switch (action.type) {
    case Actions.ACTION_SERVICES_SET_ANNOUNCEMENT_BANNER:
      return {
        ...state,
        announcementBannerShown: action.state
      };
    case Actions.ACTION_SERVICES_FETCH_SUCCESS:
      return {
        ...state,
        fetchState: "LOADED",
        services: [...action.services],
        user: action.user
      };
    case Actions.ACTION_SERVICES_FETCH_ERROR:
      return {
        ...state,
        fetchState: "ERROR",
        services: [],
        errorMessage: action.errorMessage
      };
    case Actions.ACTION_SERVICES_SET_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    case Actions.ACTION_SERVICES_SET_FILTER_NAMESPACES:
      return {
        ...state,
        filteredNamespaces: action.filteredNamespaces
      };
    case Actions.ACTION_SERVICES_SET_PAGE:
      return {
        ...state,
        currentPage: action.page
      };
  }
  return state;
}
