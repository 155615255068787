import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import * as React from "react";
import FilterBarContainer from "../containers/FilterBarContainer";
import HeaderContainer from "../containers/HeaderContainer";
import ServiceListContainer from "../containers/ServiceListContainer";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#f2f2f2",
      contrastText: "#000000",
      dark: "#c4c4c4"
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["Open Sans", "sans-serif"].join(",")
  }
});

const styles = (_: Theme) =>
  createStyles({
    cardsContainer: {
      margin: theme.spacing.unit * 10,
      marginTop: 0
    },
    filterContainer: {
      marginLeft: theme.spacing.unit * 10,
      marginRight: theme.spacing.unit * 10,
      marginTop: 0
    }
  });

interface Props extends WithStyles<typeof styles> {}

const Dashboard = withStyles(styles)(({ classes }: Props) => (
  <MuiThemeProvider theme={theme}>
    <div>
      <HeaderContainer />
      <div className={classes.filterContainer}>
        <FilterBarContainer />
      </div>
      <div className={classes.cardsContainer}>
        <ServiceListContainer />
      </div>
    </div>
  </MuiThemeProvider>
));

export default withStyles(styles(theme))(Dashboard);
