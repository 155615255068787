import { connect } from "react-redux";
import { Service } from "../models/Service";

import { Dispatch } from "redux";
import * as Actions from "../actions";
import FilterBar from "../components/FilterBar";

export interface ApplicationState {
  services: Service[];
  namespaces: string[];
  filteredNamespaces: string[];
}

const mapStateToProps = (state: ApplicationState) => {
  // unique sorted list of namespaces
  const namespaces = state.services
    .map(s => s.namespace)
    .sort()
    .filter((el, i, a) => i === a.indexOf(el));

  return {
    namespaces,
    filteredNamespaces: state.filteredNamespaces
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFilterChange: (filter: string) =>
    dispatch(Actions.dispatchSetFilter(filter)),
  onFilterNamespaceChange: (namespaces: string[]) =>
    dispatch(Actions.dispatchSetFilterNamespaces(namespaces))
});

const FilterBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterBar);

export default FilterBarContainer;
